<template>
    <grid-view 
        title="Ключи"
        filter
        url-update="/code/update/:id"
        store-module="code"
        :headers="[
            { text: 'ID', value: 'id', width: 1 },
            { text: 'Активность', value: 'active', width: 1, sortable: false },
            { text: 'Ключ', value: 'value', sortable: false },
            { text: 'Тип лицензии', value: 'license_type.name', sortable: false },
            { text: 'Источник', value: 'source', width: 1, sortable: false },
            { text: '', value: 'edit', width: 1, sortable: false },
            { text: '', value: 'remove', width: 1, sortable: false }
        ]"
        :format="formatItems"
        :checkDeletePermission="(item) => {
            return false;
        }"
    >
        <!-- Filter -->
        <template v-slot:filter="{ filter }">
            <v-row>
                <v-col lg="6" sm="12">
                    <v-select
                      dense
                      v-model="filter.active"
                      :color="$const.color.primary"
                      :items="[{
                        text: 'Все',
                        value: null
                      },{
                        text: 'Нет',
                        value: false
                      },{
                        text: 'Да',
                        value: true
                      }]"
                      label="Активность"
                    ></v-select>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        dense
                        v-model="filter.id"
                        autocomplete="new-email"
                        label="ID" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary"
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        dense
                        v-model="filter.value"
                        autocomplete="new-email"
                        label="Название" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary"
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-select
                      dense
                      v-model="filter.license_type_id"
                      :color="$const.color.primary"
                      :items="[
                        {
                            text: 'Все',
                            value: null
                        },
                        ...license_type
                      ]"
                      label="Тип лицензии"
                    ></v-select>
                </v-col>
            </v-row>
        </template>
        <!-- END Filter -->
    </grid-view>
</template>
<script>
import DataListMixin from '@/mixins/DataListMixin.vue'
import GridView from '@/components/crud/GridView.vue'
export default {
    name: 'UserIndex',
    components: { GridView }, 
    mixins: [DataListMixin],
    data() {
        return {
            license_type: []
        }
    },
    methods: {
        formatItems(items) {
            return _.map(items, (item) => {
                // Active
                _.set(item, 'active', item.active === true ? 'Да' : 'Нет');
                // Source
                _.set(item, 'source', _.get(this, `$store.state.code.sources.${item.source}`, ''));

                return item;
            })
        } 
    },
    async created() {
        this.license_type = await this.getDataList('license_type', {
            label: 'name',
            value: 'id'
        });
    }
}
</script>